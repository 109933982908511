import { useCallback, useEffect, useRef } from "react";
import type { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { analyticsTrackerApi } from "@/react/api/analyticsTrackerApi";
import { usePunditUserContext } from "@/react/contexts";
import { useDocumentHide } from "../useDocumentHide";

const BASE_URL = new URL(window.location.href).origin;

export interface NavigationTrackerProps {
  children: ReactNode;
}

export const NavigationTracker = ({ children }: NavigationTrackerProps) => {
  const { pathname, hash, search } = useLocation();
  const pageViewRefererRef = useRef(document.referrer);
  const pageExitRefererRef = useRef(document.referrer);
  const {
    masqueradingEntity,
    currentCommunity,
    currentCommunityMember,
    isLoading,
  } = usePunditUserContext();
  const pageLoadTimeRef = useRef(Date.now());
  const pageViewIdRef = useRef(uuidv4());

  const communityId = currentCommunity?.id;
  const communityMemberId = currentCommunityMember?.id;
  const shouldSkipAnalytics = !!(masqueradingEntity || isLoading);

  const fireAnalyticsEvent = useCallback(async () => {
    if (shouldSkipAnalytics) return;

    const url = BASE_URL + pathname + search + hash;

    const properties = {
      community_id: communityId,
      community_member_id: communityMemberId,
      url,
      referer: pageViewRefererRef.current,
      page_view_id: pageViewIdRef.current,
    };
    pageViewRefererRef.current = url;
    await analyticsTrackerApi.trackPageView(properties);
  }, [
    hash,
    pathname,
    search,
    communityId,
    communityMemberId,
    shouldSkipAnalytics,
  ]);

  const firePageExitEvent = useCallback(async () => {
    if (shouldSkipAnalytics) return;

    const url = BASE_URL + pathname + search + hash;
    const timeSpentMs = Date.now() - pageLoadTimeRef.current;

    const properties = {
      community_id: communityId,
      community_member_id: communityMemberId,
      url,
      referer: pageExitRefererRef.current,
      time_spent_seconds: Math.round(timeSpentMs / 1000),
      page_view_id: pageViewIdRef.current,
    };
    pageExitRefererRef.current = url;

    await analyticsTrackerApi.trackPageExit(properties);
  }, [
    hash,
    pathname,
    search,
    communityId,
    communityMemberId,
    shouldSkipAnalytics,
  ]);

  // Handle tab/window close using pagehide event
  useDocumentHide(() => void firePageExitEvent());

  // Handle route changes
  useEffect(() => {
    pageViewIdRef.current = uuidv4();
    pageLoadTimeRef.current = Date.now();
    void fireAnalyticsEvent();

    return () => {
      void firePageExitEvent();
    };
  }, [fireAnalyticsEvent, firePageExitEvent]);

  return children;
};
